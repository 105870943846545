<h1 class="title">
  Ian Vink
  <br>
  <div class="subtitle">Software Engineer</div>
</h1>

<p>
  Hoi ik ben Ian, welkom bij mijn portfolio
<br>Hieronder zijn enkele van mijn projecten te zien
</p>
<p>
  <b>Opmerking</b> I.v.m geheimhoudingsclausules zijn niet alle demo’s en codes te tonen. Bij een persoonlijk gesprek is het mogelijk nadere toelichting te geven omtrent mijn uitvoeringen
</p>
<app-project-list></app-project-list>
