<!-- <div> -->
  <!-- <label class="year">{{this.project?.year}}</label> -->
  <div class="dx-card">
    <p class="name">
      {{this.project?.name}}
    </p>

    <div class="year">
      {{this.project?.year}}
    </div>

    <img src={{this.project?.thumbnail}} alt="" width="300" height="200" *ngIf="this.project?.thumbnail != ''">

    <div class="tech">
      {{this.project?.technologies}}
    </div>

    <p class="description">
      {{this.project?.description}}
    </p>

    <div class="button-bar">
      <!-- <i class="far fa-eye"></i> -->
      <dx-button class="button" [disabled]="this.project?.demo == ''" (click)="openDemo()">Demo</dx-button>
      <dx-button class="button" [disabled]="this.project?.git == ''" (click)="openGit()">Code</dx-button>
    </div>
  </div>
<!-- </div> -->

