import { Component, Input, OnInit } from '@angular/core';
import { Project } from '../interfaces/project.interface';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit {
  @Input() project: Project | undefined;

  constructor() {}

  ngOnInit() {}

  openDemo() {
    window.open(this.project?.demo, '_blank');
  }

  openGit() {
    window.open(this.project?.git, '_blank');
  }
}
