import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {
  DevExtremeModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxTabsModule,
} from 'devextreme-angular';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './routes';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { SpecialComponent } from './special/special.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { ProjectListComponent } from './project-list/project-list.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SpecialComponent,
    ProjectCardComponent,
    ProjectListComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    DevExtremeModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxTabsModule,
    RouterModule.forRoot(appRoutes),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
