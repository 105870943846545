<div>

  <p>
    Happy Birthday love!
  </p>

  <img class="img1" alt="" src="../assets/yellow-heart.png" />
  <img class="img2" alt="" src="../assets/yellow-heart.png" />
  <img class="img3" alt="" src="../assets/yellow-heart.png" />
  <img class="img4" alt="" src="../assets/yellow-heart.png" />
  <img class="img5" alt="" src="../assets/yellow-heart.png" />

  <img class="us" alt="" src="../assets/us.PNG" />
</div>
