import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SpecialComponent } from './special/special.component';

export const appRoutes: Routes = [
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [],
    children: [{ path: '', component: HomeComponent }],
  },

  { path: '**', redirectTo: '', pathMatch: 'full' },
];
