import { Component, OnInit } from '@angular/core';

import { Project } from 'src/app/interfaces/project.interface';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
  projects: Project[] = [];

  constructor() {}

  ngOnInit() {
    const project0: Project = {
      name: 'Continu-lerende leverancier-identificatie module',
      year: '2021',
      technologies:
        'Angular (Typescript, HTML, CSS), ASP.NET Core (C#), MS SQL Server, Python',
      description:
        'Een applicatie die kernwaarde detectie en multiclass classificatie combineert om in zoveel mogelijk gevallen de juiste leverancier te identificeren, en door middel van online machine learning continu zichzelf verbetert',
      thumbnail:
        '../assets/projects/supplier-recognition/Supplier Recognition - Demo.png',
      demo: '',
      git: '',
    };

    const project1: Project = {
      name: 'Zeebodem modificatie tool',
      year: '2019-2020',
      technologies: 'Unigine (C#), AGX Dynamics (C++), Qt Creator',
      description:
        'Een tool waarmee een zeebodem mesh gewijzigd kan worden, en schepen interactie hebben met het aangepaste terrein',
      thumbnail:
        './assets/projects/seabed-modification/Seabed Modification.png',
      demo: '',
      git: '',
    };

    const project2: Project = {
      name: 'Goal-based vector field pathfinding algorithm',
      year: '2018',
      technologies: 'Swing (Java)',
      description:
        'De applicatie gebruikt een vector field om particles naar een bepaald doel te sturen. De vectoren worden berekend met een heatmap, die start op de locatie van het doelwit. De particles botsen met de omgeving, wat ook kan worden gewijzigd en opgeslagen/ingeladen middels de GUI',
      thumbnail:
        '../assets/projects/particle-flow/Particle Flow - Demonstration.png',
      demo: 'https://youtu.be/7rvWAWpkYus',
      git: 'https://github.com/Ninjaws/2D-Computer-Graphics/tree/master/EindOpdracht/Pathfinding',
    };

    const project3: Project = {
      name: '2D physics engine',
      year: '2016',
      technologies: 'Unity voor visualisatie, C#',
      description:
        'De engine simuleert biljard. Botsingen worden verwerkt via momentum en deflectie. Er wordt gebruik gemaakt van Separating Axis Theorem en een grid om de performance hoog te houden',
      thumbnail: '',
      demo: '',
      git: '',
    };

    const project4: Project = {
      name: 'Jelly-themed platforming game',
      year: '2015',
      technologies: 'SFML (C++)',
      description:
        'Een platformer geschreven in C++, met saven/laden, tile- en collisionmappen, speler- en tegenstanderslogica, collectibles en statelogica om te wisselen tussen meerdere schermen',
      thumbnail: '../assets/projects/jelly-game/Jelly Game - demo.png',
      demo: 'https://youtu.be/jCUnqbxuySI',
      git: 'https://github.com/Ninjaws/JellyGame',
    };

    const project5: Project = {
      name: 'Microcontroller Tetris (groepsproject)',
      year: '2019',
      technologies: 'ATMega128, Dot-matrix displays, 7-segment display, C',
      description:
        'Tetris geschreven in C, gevisualiseerd op dot-matrix displays, met score op een 7-segment display en een menu-systeem zichtbaar op de ATMega128',
      thumbnail: '../assets/projects/microcontroller-tetris/Setup 3.png',
      demo: 'https://youtu.be/UnNgNyXE9ZY',
      git: 'https://github.com/Ninjaws/Microcontrollers-Tetris',
    };

    const project6: Project = {
      name: 'Augmented reality video game (groepsproject)',
      year: '2019',
      technologies: 'OpenCV, OpenGL, IrrKlang, C++',
      description:
        'De game gebruikt computer vision om een rode bal te tracken die dient als controller. Er is een systeem ontwikkeld voor de animaties van de speler en tegenstanders. De wiskunde voor OpenGL is handgeschreven',
      thumbnail: '../assets/projects/ar-game/AR Game.png',
      demo: 'https://youtu.be/fHJQNnytocc',
      git: 'https://github.com/Ninjaws/AR-Applicatie',
    };

    this.projects.push(project0);
    this.projects.push(project1);
    this.projects.push(project2);
    this.projects.push(project3);
    this.projects.push(project4);
    this.projects.push(project5);
    this.projects.push(project6);
  }
}
